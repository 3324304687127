.matrix-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: "2px solid green";
  color: #0f0;
  outline: none;
}

.matrix-input {
  background-color: #000;
  color: #0f0;
  font-family: "Courier New", monospace;
  font-size: 20px;
  max-width: 80%;
  outline: none;
  border-color: #0f0;
  border: "2px solid green";
}
.matrix-input:focus {
  outline: none;
}

@media (max-width: 768px) {
  .matrix-input {
    font-size: 1em;
  }
}

.inputWrapper {
  overflow-wrap: break-word;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.play-game-button.matrix-style {
  /* Add some basic styles for the button */
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  color: black;
  text-decoration: none;
  user-select: none;
  padding: 10px 20px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 10px;
  opacity: 100%;

  /* Add the Matrix-style green color and flickering effect */
  background-color: #00ff00;
  border-radius: 0.5em;
  animation: matrix-flicker 1s infinite;
}

.results {
  display: flex;
  flex-direction: row;
  max-width: 70%;
}

@keyframes matrix-flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
