.matrix-card {
  background-color: #000;
  color: #0f0;
  border: 1px solid #0f0;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 255, 0, 0.3);
  justify-content: center;
  word-wrap: break-word;
  max-width: 90%;
  position: relative;
}

.matrix-button {
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  outline: #0f0;
  border: none;
  cursor: pointer;
  margin: 10px;
  opacity: 100%;

  background-color: #0f0;
  border-radius: 0.5em;
}

.results {
  display: flex;
  flex-direction: column;
  /* border: "1px solid red"; */
  align-items: center;
}

.matrix-card-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.matrix-card-content {
  font-size: 16px;
}
