.matrix-menubar {
  background-color: #000;
  color: #0f0;
  width: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  border-right: "1px solid red";
  height: 100%;
  flex: 0 0 200px;
  border-right: 1px solid yellow;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 70vh;
  z-index: 2;
}
.menu-toggle {
  background: none;
  border: none;
  color: #0f0;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
}

.collapsed {
  width: 1%;
  border: none;
}

.tabs-container {
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.matrix-menubar-tab {
  padding: 10px;
  cursor: pointer;
}

.matrix-menubar-tab:hover {
  background-color: goldenrod;
  color: #000;
}

.matrix-menubar-tab.active {
  background-color: #0f0;
  color: #000;
}

.matrix-button {
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  color: #0f0;
  text-decoration: none;
  padding: 10px 20px;
  outline: #0f0;
  border: none;
  cursor: pointer;
  margin: 10px;
  opacity: 100%;

  background-color: darkgreen;
  border-radius: 0.5em;
}

/* Responsive styles */
@media (max-width: 768px) {
  .matrix-menubar {
    flex-direction: column;
    width: 30%;
    height: 100%;
  }

  .collapsed {
    width: 0%;
    border: none;
  }

  .tabs-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  .matrix-menubar-tab {
    width: 80%;
    /* Additional responsive styles for each tab */
  }
}
